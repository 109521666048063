<template>
  <v-container fluid style="padding-top:0px;">
    <v-row>Nails & screws required for this order</v-row>
    <v-row>
      <v-container fluid style="background-color: white; padding-top: 0px; padding-bottom: 0px;">
        <v-row>
          <v-col cols="4">
            Number of nails <br>
            Number of colour zinc screws <br>
            Number of dacromet screws 
          </v-col>
          <v-col cols="1" align="right" style="padding-left: 0px;">
            {{$store.state.order.nails}} <br>
            {{$store.state.order.zincScrews}} <br>
            {{$store.state.order.dacrometScrews}}
          </v-col>
          <v-col>
            <v-row>{{nailPallets}} Pallet{{nailPallets === 1 ? '' : 's'}} (5,000 pcs/pallet)</v-row>
            <v-row>{{zincScrewCartons}} Carton{{zincScrewCartons === 1 ? '' : 's'}} (bags of 20) = 2,000pcs</v-row>
            <v-row>{{dacrometScrewCartons}} Carton{{dacrometScrewCartons === 1 ? '' : 's'}} (bags of 50) = 5,000pcs</v-row>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    nailPallets() {
      return Math.ceil(this.$store.state.order.nails / 5000);
    },
    zincScrewCartons() {
      return Math.ceil(this.$store.state.order.zincScrews / 2000);
    },
    dacrometScrewCartons() {
      return Math.ceil(this.$store.state.order.dacrometScrews / 5000);
    },
  }
}
</script>

<style>
    .table-cols {
        padding: 0px !important;
    }
</style>