<template>
  <v-container fluid>
    <v-row> Container loading (max 5 containers) </v-row>
    <v-row style="background-color: white">
      <v-container fluid>
        <v-row class="in-row-col">
          <v-col cols="1" class="in-row-col">Mass:</v-col>
          <v-col
            class="in-row-col"
            v-for="(container, c) in $store.state.containers"
            :key="c"
          >
            <v-progress-linear
              color="#ccc"
              height="20"
              :value="convertMass(container)"
              background-color="white"
              style="border-style: solid; border-width: 1px"
              striped
              >{{ convertMass(container) }}%</v-progress-linear
            >
          </v-col>
          <v-col class="in-row-col" style="white-space: nowrap" cols="3">
            {{ $store.getters.totalOrderWeight }} kg (Max. 21,500 kg)
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row class="in-row-col">
          <v-col cols="1" class="in-row-col">Volume:</v-col>
          <v-col
            class="in-row-col"
            v-for="(container, c) in $store.state.containers"
            :key="c"
          >
            <v-progress-linear
              color="orange"
              height="20"
              :value="convertVolume(container)"
              background-color="white"
              style="border-style: solid; border-width: 1px"
              striped
              >{{ convertVolume(container) }}%</v-progress-linear
            >
          </v-col>
          <v-col class="in-row-col" style="white-space: nowrap" cols="3">
            {{ $store.getters.totalPalletCount }} Pallets (remaining space:
            {{ $store.getters.remainingPalletCount }} pallets)
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    convertMass(container) {
      //If no more pallets will fit in the container, say the container is 100% full
      if (
        container.mass + this.$store.smallestPalletWeight >
        this.$store.maxContainerWeight
      )
        return 100;

      const massPercentage = container.mass / this.$store.state.maxContainerWeight;
      if (massPercentage > 1) return 100;
      return Math.floor(massPercentage * 100);
    },
    convertVolume(container) {
      const volumePercetage =
        container.volume / (this.$store.state.maxHeight * 6);
      if (volumePercetage > 1) return 100;
      return Math.floor(volumePercetage * 100);
    },
  },
};
</script>

<style>
.in-row-col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  line-height: 1;
}
</style>