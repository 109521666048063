<template>
  <v-container
    fluid
    style="padding-top: 10px; padding-left: 0px; padding-right: 0px"
    fill-height
    ref="itemListHolder"
  >
    <v-dialog
      v-model="showCartDialog"
      width="30%"
      persistent
      @keydown.esc="cancelAddToCart"
      @keydown.enter="submitAddToCart"
    >
      <v-card height="250px" dense style="font-size: 10px">
        <v-card-title center>{{ selectedItem.product }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="padding: 0px">
              <v-text-field
                v-model="selectedCartQuantity"
                type="number"
                label="Quantity"
                :error-messages="errorMessages"
                filled
                autofocus
              >
              </v-text-field>
            </v-row>
            <v-row style="padding: 0px">
              <v-col>
                <v-btn block tile depressed @click="cancelAddToCart">
                  Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block depressed tile @click="submitAddToCart">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout child-flex>
      <v-card>
        <v-data-table
          fixed-header
          :height="tableHeight"
          :headers="headers"
          :items="filteredItems"
          :loading="items.length === 0"
          :sortBy="['category', 'subCategory']"
          dense
          disable-pagination
          :hide-default-footer="true"
        >
          <template slot="item" slot-scope="props">
            <tr @dblclick="popCartDialog(props.item)">
              <td>
                {{ props.item.product }}
              </td>
              <td>
                {{ props.item.optionOneValue }}
              </td>
              <td>
                {{ props.item.optionTwoLabel }}
              </td>
              <td>
                {{ props.item.optionTwoValue }}
              </td>
              <td>
                {{ props.item.price | currency }}
              </td>
              <td>
                {{ $store.getters.amountInCart(props.item) }}
                <v-btn @click="popCartDialog(props.item)" icon color="orange">
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row>
          <v-col class="text-right">
            <span
              style="padding-right: 30px; font-style: italic; font-weight: bold"
              >Order Total:</span
            >
            <span
              style="padding-right: 40px; font-weight: bold; font-size: 20px"
              >{{ $store.state.order.total | currency }}</span
            >
            <v-btn
              color="orange"
              style="color: white; margin-right: 5%"
              @click="$router.push('checkout')"
              >Checkout</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Product", value: "product" },
        { text: "Material", value: "optionOneValue" },
        { text: "Option", value: "optionTwoLabel" },
        { text: "", value: "optionTwoValue" },
        { text: "Price", value: "price" },
        { text: "Cart", value: "actions" },
      ],
      showCartDialog: false,
      selectedItem: {},
      selectedCartQuantity: "",
      errorMessages: [],
      tableHeight: 0,
    };
  },
  methods: {
    popCartDialog(item) {
      console.log(item);
      this.selectedItem = item;
      const currentAmount = this.$store.getters.amountInCart(item);
      if (currentAmount !== 0) this.selectedCartQuantity = currentAmount;
      this.showCartDialog = true;
    },
    cancelAddToCart() {
      this.showCartDialog = false;
      this.selectedItem = {};
      this.selectedCartQuantity = "";
      this.errorMessages = [];
    },
    submitAddToCart() {
      if (!this.selectedCartQuantity || this.selectedCartQuantity < 0) {
        this.errorMessages = ["Please enter a quantity 0 or greater"];
        return;
      }
      this.$store.dispatch("setItemInCart", {
        item: this.selectedItem,
        quantity: this.selectedCartQuantity,
      });
      this.showCartDialog = false;
      this.selectedItem = {};
      this.selectedCartQuantity = "";
      this.errorMessages = [];
    },
  },
  mounted() {
    this.tableHeight =
      window.innerHeight * 0.85 -
      this.$refs.itemListHolder.getBoundingClientRect().top;
  },
  computed: {
    steelFilter() {
      return this.$store.state.steelFilter;
    },
    filterCategory() {
      return this.$store.state.filterCategory;
    },
    filterSubCategory() {
      return this.$store.state.filterSubCategory;
    },
    filterSearch() {
      return this.$store.state.filterSearch;
    },
    filterSelected() {
      return this.$store.state.selectedItemsOnly;
    },
    items() {
      return this.$store.state.inventory[0] === undefined
        ? []
        : this.$store.state.inventory || [];
    },
    filteredItems() {
      if (this.items.length < 1) return [];
      let items = JSON.parse(JSON.stringify(this.items));
      if (this.filterCategory)
        items = items.filter((item) => item.category === this.filterCategory);
      if (this.filterSubCategory) {
        items = items.filter(
          (item) => item.subCategory === this.filterSubCategory
        );
      }
      if (this.filterSearch)
        items = items.filter(
          (item) =>
            item.product
              .toUpperCase()
              .indexOf(this.filterSearch.toUpperCase()) !== -1
        );
      console.log(`FILTERED: ${this.filterSelected}, (a${this.$store.getters.isSelectedItemsOnly})`)

      if (this.steelFilter !== "Any")
        items = items.filter(
          (item) => item.optionOneValue === this.steelFilter
        );
      if (this.filterSelected)
        items = items.filter((item) => {
          let inOrder = false;
          for (let i = 0; i < this.$store.state.order.items.length; i++) {
            if (this.$store.state.order.items[i].code === item.code) {
              inOrder = true;
              break;
            }
          }
          return inOrder;
        });
      return items;
    },
  },
};
</script>